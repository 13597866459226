import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { PrivacyAndPolicyDialog } from 'src/app/dialogs/privacy-and-policy-dialog/privacy-and-policy-dialog';
import { TermsAndConditionsDialog } from 'src/app/dialogs/terms-and-conditions-dialog/terms-and-conditions-dialog';
import { StatusLabelActions } from 'src/app/store/actions/status-label.actions';
import { CANADA_STATES, COUNTRIES_NAMES, USA_STATES } from 'src/app/store/constants';
import {
    EMAIL_VALIDATOR_REG_EXP,
    LANGUAGES_TNT,
    PASSWORD_VALIDATOR_REG_EXP,
    StatusLabelType,
    TEXT_INPUT,
    TNT_COUNTRIES_LIST,
    TNT_PASSWORD_VALIDATOR_REG_EXP,
} from 'src/app/store/constants/common.constants';
import { ILanguage, IStatusLabel } from 'src/app/store/interfaces';
import { selectIsStatusLabel } from 'src/app/store/selectors/status.selectors';
import { ApiService, LocalStorageService } from 'src/app/store/services';
import { PasswordValidatorService } from 'src/app/store/services/password-validator/password-validator.service';
import { IAppState } from 'src/app/store/state/app.state';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { EditPhotoDialogComponent } from '../../dialogs/edit-photo-dialog/edit-photo-dialog.component';
import { skin } from 'src/white-labels';
import { PhoneInputComponent } from 'src/app/shared/phone-input/phone-input.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-create-account',
    templateUrl: './create-account.component.html',
    styleUrls: ['./create-account.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateAccountComponent implements OnInit, OnDestroy {
    @ViewChild(MatAutocompleteTrigger) matAutocompleteTrigger: MatAutocompleteTrigger;
    @ViewChild(PhoneInputComponent) phoneInputComponent: PhoneInputComponent;

    profilePhotoSrc: SafeUrl;
    createAccountError$: Observable<IStatusLabel> = this.store.select(selectIsStatusLabel);
    accountDetailsForm: FormGroup = this.formBuilder.group(
        {
            userPhoto: [null],
            firstName: [
                '',
                [
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(30),
                    Validators.pattern(TEXT_INPUT),
                ],
            ],
            lastName: [
                '',
                [
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(30),
                    Validators.pattern(TEXT_INPUT),
                ],
            ],
            email: ['', [Validators.required, Validators.pattern(EMAIL_VALIDATOR_REG_EXP)]],
            password: [
                '',
                [
                    Validators.required,
                    Validators.pattern(
                        skin.whiteLabel !== 'TAGANDTRACK'
                            ? PASSWORD_VALIDATOR_REG_EXP
                            : TNT_PASSWORD_VALIDATOR_REG_EXP,
                    ),
                ],
            ],
            passwordConfirmed: ['', [Validators.required]],
            terms: [false, [Validators.requiredTrue]],
            privacy: [false, [Validators.requiredTrue]],
            // for TNT
            language: ['en', [Validators.required]],
            country: skin.whiteLabel === 'TAGANDTRACK' ? ['pt', [Validators.required]] : [''],
            // NOT for TNT
            metricSystem: ['kph'],
            state: [''],
        },
        {
            validators: [this.validatorService.passwordsValidator('password', 'passwordConfirmed')],
        },
    );

    showPassword: boolean;
    showConfirmePassword: boolean;
    skin = skin;
    isPrivacyChecked: boolean;
    isTermsChecked: boolean;

    readonly lenguages: ILanguage[] = LANGUAGES_TNT;
    readonly countries = TNT_COUNTRIES_LIST;
    readonly allCountries = COUNTRIES_NAMES;
    readonly canadaStates = CANADA_STATES;
    readonly usaStates = USA_STATES;

    private destroyed$ = new Subject<void>();

    constructor(
        private formBuilder: FormBuilder,
        private apiService: ApiService,
        private validatorService: PasswordValidatorService,
        private store: Store<IAppState>,
        public dialog: MatDialog,
        private router: Router,
        private sanitizer: DomSanitizer,
        private cdRef: ChangeDetectorRef,
        private localStorage: LocalStorageService,
        private translate: TranslateService,
    ) {}

    ngOnInit() {
        if (skin.whiteLabel !== 'TAGANDTRACK') {
            this.accountDetailsForm.controls['country'].valueChanges.subscribe((value) => {
                if (value == 'CA' || value == 'US') {
                    this.accountDetailsForm.controls['state'].patchValue(null);
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();

        this.store.dispatch(StatusLabelActions.hideStatusLabel());
    }

    changeVisiblePassword() {
        this.showPassword = !this.showPassword;
    }

    changeVisibleConfirmPassword() {
        this.showConfirmePassword = !this.showConfirmePassword;
    }

    openProfilePhotoDialog(event: Event): void {
        const dialog = this.dialog.open(EditPhotoDialogComponent, {
            data: { isHiddenDevicesBar: true },
            autoFocus: false,
            width: '600px',
            backdropClass: 'dialogBackground',
        });

        dialog
            .afterClosed()
            .pipe(filter((inValue) => Boolean(inValue)))
            .subscribe((inValue) => {
                this.accountDetailsForm.patchValue({ userPhoto: inValue });
                const mediaType = 'application/image';
                const blob = new Blob([inValue], { type: mediaType });
                const unsafeImg = URL.createObjectURL(blob);
                this.profilePhotoSrc = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
                this.cdRef.markForCheck();
            });
    }

    openDialog(event: Event, isShowTerms: boolean, isDynamicHeight?: boolean): void {
        event.stopPropagation();
        event.preventDefault();

        const dialog = this.dialog.open(isShowTerms ? TermsAndConditionsDialog : PrivacyAndPolicyDialog, {
            data: {
                checked: isShowTerms
                    ? this.accountDetailsForm.value.terms
                    : this.accountDetailsForm.value.privacy,
            },
            autoFocus: false,
            width: '90vw',
            height: isDynamicHeight && skin.whiteLabel === 'TAGANDTRACK' ? 'auto' : '80vh',
            backdropClass: 'dialogBackground',
        });

        dialog
            .afterClosed()
            .pipe(filter((inValue) => Boolean(inValue)))
            .subscribe((inValue) =>
                isShowTerms
                    ? this.accountDetailsForm.patchValue({ terms: inValue })
                    : this.accountDetailsForm.patchValue({ privacy: inValue }),
            );
    }

    submitAccountDetailsForm(isValid: boolean): void {
        this.accountDetailsForm.markAllAsTouched();
        this.phoneInputComponent.validatePhoneForm();
        this.isPrivacyChecked = this.accountDetailsForm.controls['privacy'].value;
        this.isTermsChecked = this.accountDetailsForm.controls['terms'].value;

        if (
            isValid &&
            this.phoneInputComponent.countryCtrl.valid &&
            this.phoneInputComponent.phoneNumb.valid
        ) {
            const formData = this.accountDetailsForm.value;
            const phoneCountryData = this.phoneInputComponent.countryCtrl.value;
            const phoneNumber = this.phoneInputComponent.phoneNumb.value;

            let userData = {
                userIcon: formData.userPhoto,
                clientId: skin.clientId,
                firstName: formData.firstName,
                lastName: formData.lastName,
                username: formData.email,
                password: formData.password,
                phone: `+${phoneCountryData[2]}${phoneNumber}`,
                phoneCountryCode: phoneCountryData[1],
                whitelabel: this.skin.whiteLabel,
            };

            if (this.skin.whiteLabel !== 'TAGANDTRACK') {
                userData['country'] = formData.country;
                userData['state'] =
                    formData.country == 'CA' || formData.country == 'US' ? formData.state : '';
                userData['speedUnit'] = formData.metricSystem;
            }

            if (this.skin.whiteLabel === 'TAGANDTRACK') {
                userData['language'] = formData.language;
                userData['country'] = formData.country;

                if (this.localStorage.getItem('language') !== formData.language) {
                    this.localStorage.setItem('language', formData.language);
                    this.translate.use(formData.language);
                }
            }

            const formDataDTO: FormData = new FormData();
            for (var key in userData) {
                formDataDTO.append(key, userData[key]);
            }

            this.apiService
                .createAccount(formDataDTO)
                .pipe(takeUntil(this.destroyed$))
                .subscribe(
                    (res) => {
                        if (this.skin.whiteLabel === 'TAGANDTRACK') {
                            this.router.navigate(['/verify-account'], {
                                queryParams: { email: formData.email, userToken: res['user_token'] },
                            });
                        } else {
                            this.router.navigate(['/activation-account'], {
                                queryParams: { email: formData.email },
                            });
                        }
                    },
                    ({ error }) => {
                        if (error.errors && error.errors[0]?.property_name === 'phone') {
                            this.store.dispatch(
                                StatusLabelActions.showStatusLabel({
                                    statusLabel: {
                                        status: this.translate.instant('invalidContactPhone'),
                                        labelType: StatusLabelType.WARNING,
                                    },
                                }),
                            );
                            this.phoneInputComponent.phoneNumb.setErrors({ invalid: true });
                            this.phoneInputComponent.validatePhoneForm();
                        } else {
                            this.store.dispatch(
                                StatusLabelActions.showStatusLabel({
                                    statusLabel: {
                                        status: error.message_key
                                            ? this.translate.instant(error.message_key)
                                            : error.message,
                                        labelType: StatusLabelType.WARNING,
                                    },
                                }),
                            );
                        }
                    },
                );
        }
    }
}
