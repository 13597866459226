import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { Subject, filter, takeUntil } from 'rxjs';
import { AuthActions } from 'src/app/store/actions/auth.actions';
import { BASE_REDIRECT_URL, SOCIAL_NETWORKS_PROVIDERS } from 'src/app/store/constants';
import { ISocialUserInfo } from 'src/app/store/interfaces';
import { ApiService } from 'src/app/store/services';
import { EncryptionService } from 'src/app/store/services/auth/auth-encryption-algorithm.service';
import { AuthService } from 'src/app/store/services/auth/auth.service';
import { IAppState } from 'src/app/store/state/app.state';
import { skin } from 'src/white-labels';

declare var AppleID;

@Component({
    selector: 'app-social-btns',
    templateUrl: './social-btns.component.html',
    styleUrls: ['./social-btns.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialBtnsComponent implements OnInit, OnDestroy {
    @Input() buttonToShow: string;
    private destroyed$ = new Subject<void>();
    skin = skin;

    constructor(
        private socialAuthService: SocialAuthService,
        private encryptionService: EncryptionService,
        private authService: AuthService,
        private apiService: ApiService,
        private store: Store<IAppState>,
        private router: Router,
    ) {}

    ngOnInit(): void {
        // this.socialAuthService.authState
        //     .pipe(
        //         filter((authResponse) => Boolean(authResponse)),
        //         takeUntil(this.destroyed$),
        //     )
        //     .subscribe((authResponse) => {
        //         console.log(authResponse);
        //         this.verifyAndRegisterUser({
        //             email: authResponse.email,
        //             provider: authResponse.provider,
        //             token: authResponse.idToken || authResponse.authToken,
        //             firstName: authResponse.firstName,
        //             lastName: authResponse.lastName,
        //             photoUrl: authResponse.photoUrl,
        //         });
        //     });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    async signInWithGoogle(): Promise<void> {
        const authResponse = await this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);

        this.verifyAndRegisterUser({
            email: authResponse.email,
            provider: authResponse.provider,
            token: authResponse.idToken || authResponse.authToken,
            firstName: authResponse.firstName,
            lastName: authResponse.lastName,
            photoUrl: authResponse.photoUrl || authResponse['accountIconUrl'],
        });
    }

    async signInWithFB(): Promise<void> {
        const authResponse = await this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);

        this.verifyAndRegisterUser({
            email: authResponse.email,
            provider: authResponse.provider,
            token: authResponse.idToken || authResponse.authToken,
            firstName: authResponse.firstName,
            lastName: authResponse.lastName,
            photoUrl: authResponse.photoUrl,
        });
    }

    async signInWithApple() {
        try {
            AppleID.auth.init({
                clientId: 'com.web.trackimo.authclient',
                scope: 'name email',
                redirectURI: `${BASE_REDIRECT_URL}/auth/apple/callback`,
                state: 'init',
                nonce: 'test',
                usePopup: true,
            });
            const data = await AppleID.auth.signIn();
            console.log(data);

            const parsedData = this.parseJwt(data.authorization.id_token);
            console.log(parsedData);

            this.verifyAndRegisterUser({
                email: parsedData.email,
                provider: SOCIAL_NETWORKS_PROVIDERS.apple,
                token: data.authorization.code,
                firstName: '',
                lastName: '',
            });
        } catch (error) {
            console.log(error);
        }
    }

    private verifyAndRegisterUser(userInfo: ISocialUserInfo): void {
        this.apiService
            .emailCheck({
                email: this.encryptionService.encrypt(userInfo.email, skin.whiteLabel),
                whitelabel: skin.whiteLabel,
            })
            .pipe(takeUntil(this.destroyed$))
            .subscribe(({ userExist }) => {
                console.log(userExist, userInfo);

                if (userExist) {
                    const userData = {
                        accessToken: userInfo.token,
                        whitelabel: skin.whiteLabel,
                        client_id: skin.clientId,
                        redirect_uri: `${BASE_REDIRECT_URL}/api/internal/v1/oauth_redirect`,
                        firstName: userInfo.firstName,
                        lastName: userInfo.lastName,
                    };

                    if (userInfo.provider === SOCIAL_NETWORKS_PROVIDERS.apple) {
                        userData['sourceAppleLogin'] = 'web';
                        userData['appleCode'] = userInfo.token;

                        delete userData.accessToken;
                    }

                    this.store.dispatch(
                        AuthActions.loginWithSocial({
                            userData,
                            provider: userInfo.provider.toLocaleLowerCase(),
                        }),
                    );
                } else {
                    this.authService.lastLoginUserInfo = userInfo;
                    this.router.navigate(['/register-account']);
                }
            });
    }

    private parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join(''),
        );

        return JSON.parse(jsonPayload);
    }
}
