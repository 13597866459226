import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { DevicesActions } from 'src/app/store/actions/devices.actions';
import { EventsActions } from 'src/app/store/actions/events.actions';
import { IEvent } from 'src/app/store/interfaces';
import { ApiService } from 'src/app/store/services';
import { SoundService } from 'src/app/store/services/sound.service';
import { IAppState } from 'src/app/store/state/app.state';

@Component({
    selector: 'app-map-sos',
    templateUrl: './map-sos.component.html',
    styleUrls: ['./map-sos.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapSosComponent implements OnInit, OnDestroy {
    isShowList: boolean;
    sosMsgs: IEvent[];
    destroyed$ = new Subject<void>();

    constructor(
        private store: Store<IAppState>,
        private readonly actions$: Actions,
        private cdRef: ChangeDetectorRef,
        private router: Router,
        private apiService: ApiService,
        private soundService: SoundService,
    ) {}

    ngOnInit(): void {
        this.actions$
            .pipe(ofType(EventsActions.getSOSDevicesEventsSuccess), takeUntil(this.destroyed$))
            .subscribe(({ response }) => {
                let sosMsgs = JSON.parse(JSON.stringify(response.alarmTypesDevices));

                if (this.sosMsgs?.length === sosMsgs.length) {
                    if (!sosMsgs.length) {
                        this.soundService.stopAudio();
                    }
                    return;
                }

                if (sosMsgs.length) {
                    this.soundService.playAudio();

                    sosMsgs = sosMsgs.map((sos) => {
                        if ((sos.lat, sos.lng)) {
                            this.apiService
                                .getAddress(sos.lat, sos.lng)
                                .pipe(takeUntil(this.destroyed$))
                                .subscribe((res) => {
                                    sos.address = res;
                                    this.cdRef.markForCheck();
                                });
                        }

                        return sos;
                    });
                } else {
                    this.soundService.stopAudio();
                }
                this.sosMsgs = sosMsgs;
                this.cdRef.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    showList(): void {
        this.isShowList = !this.isShowList;
    }

    readEvent(event: IEvent): void {
        console.log(event);

        this.store.dispatch(DevicesActions.updateDeviceEvent({ events: [event] }));
        this.sosMsgs = this.sosMsgs.filter((sos) => sos.eventId !== event.eventId);
        if (!this.sosMsgs.length) {
            this.isShowList = false;
        }
    }

    dismissAll(events: IEvent[]) {
        this.store.dispatch(DevicesActions.updateDeviceEvent({ events }));
        this.sosMsgs = null;
        this.isShowList = false;
    }

    goToDevice(event: IEvent): void {
        this.isShowList = false;
        this.store.dispatch(DevicesActions.updateDeviceEvent({ events: [event] }));
        this.sosMsgs = this.sosMsgs.filter((sos) => sos.eventId !== event.eventId);
        this.router.navigate(['/map/devices/', event.device_id]);
    }
}
