import { IDeviceFull, IDeviceShort, ISubscription } from '../interfaces';

export interface IDeviceState {
    devices: IDeviceShort[];
    selectedDevice: IDeviceFull;
    devicesSubscriptions?: ISubscription[];
    devicesTotalCount: number;
}

export const initialDeviceState: IDeviceState = {
    devices: undefined,
    selectedDevice: undefined,
    devicesTotalCount: undefined,
};
